window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.playlists = window.locales.am.playlists || {};window.locales.am.playlists.playlist_link_box = window.locales.am.playlists.playlist_link_box || {};window.locales.am.playlists.playlist_link_box = {...window.locales.am.playlists.playlist_link_box, ...{
    "active": "ንቁ",           
    "completed": "ተጠናቋል።",           
    "courses": "ኮርሶች",           
    "create_your_own": "የራስዎን ይፍጠሩ",           
    "edit": "አርትእ",           
    "edit_content": "ብጁ የአጫዋች ዝርዝሮች እስካሁን አይገኝም።",           
    "edit_title": "ባህርይ እስካሁን አይገኝም።",           
    "estimated_time": "EST ጊዜ",           
    "hours": "ሰአታት",           
    "use_playlist_editor": "የራስዎን የኮርሶች ተርታ ለመፍጠር የእኛን የአጫዋች ዝርዝር አርታዒ ይጠቀሙ።",           
    "view": "ይመልከቱ",           
    "x_courses": "{{num}} ኮርሶች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.playlists = window.locales.ar.playlists || {};window.locales.ar.playlists.playlist_link_box = window.locales.ar.playlists.playlist_link_box || {};window.locales.ar.playlists.playlist_link_box = {...window.locales.ar.playlists.playlist_link_box, ...{
    "active": "نشطة",           
    "completed": "مكتملة",           
    "courses": "الدورات",           
    "create_your_own": "إنشاء الدورات الخاصة بك",           
    "edit": "تعديل",           
    "edit_content": "تخصيص قوائم التشغيل غير متاح بعد.",           
    "edit_title": "الميزة غير متاحة بعد",           
    "estimated_time": "الوقت المقدر",           
    "hours": "ساعات",           
    "use_playlist_editor": "استخدام محرر قائمة التشغيل الخاص بنا لإنشاء ترتيبك الخاص بالدورات.",           
    "view": "عرض",           
    "x_courses": "{{num}} الدورات"           
}
};window.locales.en = window.locales.en || {};window.locales.en.playlists = window.locales.en.playlists || {};window.locales.en.playlists.playlist_link_box = window.locales.en.playlists.playlist_link_box || {};window.locales.en.playlists.playlist_link_box = {...window.locales.en.playlists.playlist_link_box, ...{
    "active": "ACTIVE",
    "completed": "COMPLETED",
    "courses": "COURSES",
    "create_your_own": "CREATE YOUR OWN",
    "edit": "EDIT",
    "edit_content": "Custom playlists are not yet available.",
    "edit_title": "Feature not yet available",
    "estimated_time": "EST TIME",
    "hours": "hours",
    "use_playlist_editor": "Use our playlist editor to create your own sequence of courses.",
    "view": "VIEW",
    "x_courses": "{{num}} COURSES"
}
};window.locales.es = window.locales.es || {};window.locales.es.playlists = window.locales.es.playlists || {};window.locales.es.playlists.playlist_link_box = window.locales.es.playlists.playlist_link_box || {};window.locales.es.playlists.playlist_link_box = {...window.locales.es.playlists.playlist_link_box, ...{
    "average_lesson_time": "AVG TIEMPO LECCIÓN",
    "completed": "TERMINADO",           
    "courses": "CURSOS",           
    "edit": "EDITAR",           
    "hours": "horas",           
    "view": "VER",           
    "create_your_own": "CREA TU PROPIO",           
    "estimated_time": "TIEMPO ESTIMADO",           
    "use_playlist_editor": "Utiliza nuestro editor de playlists para crear tu propia secuencia de cursos.",           
    "active": "ACTIVO",           
    "edit_content": "Las playlists personalizadas aún no están disponibles.",           
    "edit_title": "La función hasta el momento no está disponible",           
    "x_courses": "{{num}} CURSOS"           
}
};window.locales.it = window.locales.it || {};window.locales.it.playlists = window.locales.it.playlists || {};window.locales.it.playlists.playlist_link_box = window.locales.it.playlists.playlist_link_box || {};window.locales.it.playlists.playlist_link_box = {...window.locales.it.playlists.playlist_link_box, ...{
    "active": "ATTIVA",           
    "completed": "COMPLETATA",           
    "courses": "CORSI",           
    "create_your_own": "CREA LA TUA",           
    "edit": "MODIFICA",           
    "edit_content": "Le playlist personalizzate non sono ancora disponibili.",           
    "edit_title": "Funzione ancora non disponibile",           
    "estimated_time": "TEMPO STIMATO",           
    "hours": "ore",           
    "use_playlist_editor": "Usa il nostro editor di playlist per creare la tua sequenza di corsi personale.",           
    "view": "VISUALIZZA",           
    "x_courses": "{{num}} CORSI"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.playlists = window.locales.zh.playlists || {};window.locales.zh.playlists.playlist_link_box = window.locales.zh.playlists.playlist_link_box || {};window.locales.zh.playlists.playlist_link_box = {...window.locales.zh.playlists.playlist_link_box, ...{
    "active": "有效",           
    "completed": "已完成",           
    "courses": "课程",           
    "create_your_own": "创建您自己的",           
    "edit": "编辑",           
    "edit_content": "定制任务列表尚未提供。",           
    "edit_title": "功能尚未提供",           
    "estimated_time": "估计时间",           
    "hours": "小时",           
    "use_playlist_editor": "使用我们的任务列表编辑器可创建您自己的课程序列。",           
    "view": "查看",           
    "x_courses": "{{num}} 个课程"           
}
};