window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.playlists = window.locales.am.playlists || {};window.locales.am.playlists.playlist_map = window.locales.am.playlists.playlist_map || {};window.locales.am.playlists.playlist_map = {...window.locales.am.playlists.playlist_map, ...{
    "specializations_title": "ስፔሻላይዜሽንስ",           
    "exams_title": "ፈተናዎች",           
    "specializations_caption": "{{numRequired}} ወይም ተጨማሪ ያስፈልጋል • {{numComplete}} ተጠናቋል"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.playlists = window.locales.ar.playlists || {};window.locales.ar.playlists.playlist_map = window.locales.ar.playlists.playlist_map || {};window.locales.ar.playlists.playlist_map = {...window.locales.ar.playlists.playlist_map, ...{
    "locked_message": "يتم فتح المنهج الكامل إذا تم قبولك في برنامج MBA",           
    "locked_message_rejected": "المنهج الكامل متاح فقط كجزءٍ من برنامج MBA.",           
    "specializations_title": "التخصصات",
    "exams_title": "EXAMS",
    "specializations_caption": "{{numRequired}} أو أكثر مطلوب • {{numComplete}} مكتمل"
}
};window.locales.en = window.locales.en || {};window.locales.en.playlists = window.locales.en.playlists || {};window.locales.en.playlists.playlist_map = window.locales.en.playlists.playlist_map || {};window.locales.en.playlists.playlist_map = {...window.locales.en.playlists.playlist_map, ...{
    "specializations_title": "SPECIALIZATIONS",
    "specializations_caption": "{{numRequired}} OR MORE REQUIRED • {{numComplete}} COMPLETED",
    "exams_title": "EXAMS"
}
};window.locales.es = window.locales.es || {};window.locales.es.playlists = window.locales.es.playlists || {};window.locales.es.playlists.playlist_map = window.locales.es.playlists.playlist_map || {};window.locales.es.playlists.playlist_map = {...window.locales.es.playlists.playlist_map, ...{
    "locked_message": "El plan de estudios completo se desbloquea si es aceptado en el programa de MBA",           
    "locked_message_rejected": "El plan de estudios completo solo está disponible como parte del programa de MBA.",           
    "specializations_title": "ESPECIALIZACIONES",
    "exams_title": "Exámenes",
    "specializations_caption": "{{numRequired}} O MÁS REQUERIDOS • {{numComplete}} COMPLETADOS"
}
};window.locales.it = window.locales.it || {};window.locales.it.playlists = window.locales.it.playlists || {};window.locales.it.playlists.playlist_map = window.locales.it.playlists.playlist_map || {};window.locales.it.playlists.playlist_map = {...window.locales.it.playlists.playlist_map, ...{
    "specializations_title": "SPECIALIZZAZIONI",           
    "exams_title": "ESAMI",
    "specializations_caption": "{{numRequired}} O PIÙ RICHIESTI • {{numComplete}} COMPLETATO"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.playlists = window.locales.zh.playlists || {};window.locales.zh.playlists.playlist_map = window.locales.zh.playlists.playlist_map || {};window.locales.zh.playlists.playlist_map = {...window.locales.zh.playlists.playlist_map, ...{
    "locked_message": "如果您被录取进入 MBA 计划，将解锁全套课程。",           
    "locked_message_rejected": "全套课程仅在我们的 MBA 计划中提供。",           
    "specializations_title": "专项",           
    "exams_title": "测验",           
    "specializations_caption": "{{numRequired}}或更多需要 • {{numComplete}}已完成"
}
};