window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.playlists = window.locales.am.playlists || {};window.locales.am.playlists.playlist_dashboard = window.locales.am.playlists.playlist_dashboard || {};window.locales.am.playlists.playlist_dashboard = {...window.locales.am.playlists.playlist_dashboard, ...{
    "activate_playlist": "የአጫዋች ዝርዝርን ያንቁ",           
    "activate_x": "ይህን የአጫዋች ዝርዝር ማንቃት የ <strong>{{playlistName}}</strong> አጫዋች ዝርዝርን እንዳይሰራ ያደርገዋል",           
    "playlist_active": "የነቃ የአጫዋች ዝርዝር",           
    "tap_to_remove": "ለማስወገድ ነካ ያድርጉ",           
    "title": "የአጫዋች ዝርዝሮች ዝርዝሮች",           
    "view_on_dashboard": "ዳሽቦርድ ላይ ይመልከቱ",           
    "x_courses": "{{num}} ኮርሶች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.playlists = window.locales.ar.playlists || {};window.locales.ar.playlists.playlist_dashboard = window.locales.ar.playlists.playlist_dashboard || {};window.locales.ar.playlists.playlist_dashboard = {...window.locales.ar.playlists.playlist_dashboard, ...{
    "activate_playlist": "تفعيل قائمة التشغيل",           
    "activate_x": "تفعيل قائمة التشغيل هذه سوف يبطل قائمة تشغيل <strong>{{playlistName}}</strong>",           
    "playlist_active": "قائمة التشغيل النشطة",           
    "tap_to_remove": "انقر للإزالة",           
    "title": "تفاصيل قائمة التشغيل",           
    "view_on_dashboard": "عرض على لوحة التحكم",           
    "x_courses": "{{num}} الدورات"           
}
};window.locales.en = window.locales.en || {};window.locales.en.playlists = window.locales.en.playlists || {};window.locales.en.playlists.playlist_dashboard = window.locales.en.playlists.playlist_dashboard || {};window.locales.en.playlists.playlist_dashboard = {...window.locales.en.playlists.playlist_dashboard, ...{
    "activate_playlist": "ACTIVATE PLAYLIST",
    "activate_x": "Activating this playlist will deactivate the <strong>{{playlistName}}</strong> playlist",
    "playlist_active": "PLAYLIST ACTIVE",
    "tap_to_remove": "Tap to Remove",
    "title": "PLAYLIST DETAILS",
    "view_on_dashboard": "VIEW ON DASHBOARD",
    "x_courses": "{{num}} COURSES"
}
};window.locales.es = window.locales.es || {};window.locales.es.playlists = window.locales.es.playlists || {};window.locales.es.playlists.playlist_dashboard = window.locales.es.playlists.playlist_dashboard || {};window.locales.es.playlists.playlist_dashboard = {...window.locales.es.playlists.playlist_dashboard, ...{
    "activate_playlist": "ACTIVAR PLAYLIST",           
    "activate_x": "La activación de esta playlist desactivará la playlist <strong>{{playlistName}}</strong>",           
    "title": "DETALLES DE LA <br>PLAYLIST",           
    "x_courses": "{{num}} CURSOS",           
    "playlist_active": "PLAYLIST ACTIVA",           
    "tap_to_remove": "Pulsa para eliminar",           
    "view_on_dashboard": "VER EN EL PANEL DE CONTROL"           
}
};window.locales.it = window.locales.it || {};window.locales.it.playlists = window.locales.it.playlists || {};window.locales.it.playlists.playlist_dashboard = window.locales.it.playlists.playlist_dashboard || {};window.locales.it.playlists.playlist_dashboard = {...window.locales.it.playlists.playlist_dashboard, ...{
    "activate_playlist": "ATTIVA PLAYLIST",           
    "activate_x": "Attivare questa playlist disattiverà la playlist <strong>{{playlistName}}</strong>",           
    "playlist_active": "PLAYLIST ATTIVA",           
    "tap_to_remove": "Tocca per rimuovere",           
    "title": "DETTAGLI PLAYLIST",           
    "view_on_dashboard": "VISUALIZZA SULLA BACHECA",           
    "x_courses": "{{num}} CORSI"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.playlists = window.locales.zh.playlists || {};window.locales.zh.playlists.playlist_dashboard = window.locales.zh.playlists.playlist_dashboard || {};window.locales.zh.playlists.playlist_dashboard = {...window.locales.zh.playlists.playlist_dashboard, ...{
    "activate_playlist": "激活任务列表",           
    "activate_x": "激活此任务列表将停用 <strong>{{playlistName}}</strong> 任务列表",           
    "playlist_active": "任务列表有效",           
    "tap_to_remove": "点按以删除",           
    "title": "任务列表详细信息",           
    "view_on_dashboard": "在控制面板上查看",           
    "x_courses": "{{num}} 个课程"           
}
};