import { type Nullable } from '@Types';
import { type BrandConfig, type Brand, type BrandOrInstitutionId } from 'AppBranding';
import { type ProgramPreferencesStateKey } from 'Careers';
import { type CompletionDocumentType } from 'Cohorts';
import { type FormConfig } from 'FormConfigs';

export enum ProgramField {
    ai_for_technical_leaders_cert = 'ai_for_technical_leaders_cert',
    bus_analytics_leaders_cert = 'bus_analytics_leaders_cert',
    career_network_only = 'career_network_only',
    cmo_cert = 'cmo_cert',
    cto_cert = 'cto_cert',
    data_science_foundations_cert = 'data_science_foundations_cert',
    executive_cert = 'executive_cert',
    // This is the legacy name for the program field and what is in the db for C-FIN-## programs
    fin_for_non_fin_managers_cert = 'fin_for_non_fin_managers_cert',
    founders_cert = 'founders_cert',
    jordanian_math = 'jordanian_math',
    learn_code_gpt_cert = 'learn_code_gpt_cert',
    master_of_business_administration = 'master_of_business_administration',
    master_of_science_in_business_analytics = 'master_of_science_in_business_analytics',
    master_of_science_in_software_engineering = 'master_of_science_in_software_engineering',
    paid_cert_competitive_strategy = 'paid_cert_competitive_strategy',
    paid_cert_data_analytics = 'paid_cert_data_analytics',
    paid_cert_entrepreneurial_strategy = 'paid_cert_entrepreneurial_strategy',
    paid_cert_essentials_of_management = 'paid_cert_essentials_of_management',
    paid_cert_financial_accounting = 'paid_cert_financial_accounting',
    paid_cert_marketing_basics = 'paid_cert_marketing_basics',
    paid_cert_operations_management = 'paid_cert_operations_management',
    prototype_gpt_cert = 'prototype_gpt_cert',
    the_business_certificate = 'the_business_certificate',
    tyb_ai_cert = 'tyb_ai_cert',
    tyb_blockchain_cert = 'tyb_blockchain_cert',
    valar_leadership_accelerator = 'valar_leadership_accelerator',
}

export enum ProgramType {
    mba = 'mba',
    mba_leadership = 'mba_leadership',
    emba = 'emba',
    emba_strategic_leadership = 'emba_strategic_leadership',
    msba = 'msba',
    msse = 'msse',
    vla = 'vla',
    vla_aspiring_managers = 'vla_aspiring_managers',
    career_network_only = 'career_network_only',
    the_business_certificate = 'the_business_certificate',
    jordanian_math = 'jordanian_math',
    demo = 'demo',
    external = 'external',
    tyb_ai_cert = 'tyb_ai_cert',
    tyb_blockchain_cert = 'tyb_blockchain_cert',
    learn_code_gpt_cert = 'learn_code_gpt_cert',
    // This is the legacy name for the program type and what is in the db for C-FIN-## programs
    fin_for_non_fin_managers_cert = 'fin_for_non_fin_managers_cert',
    bus_analytics_leaders_cert = 'bus_analytics_leaders_cert',
    data_science_foundations_cert = 'data_science_foundations_cert',
    prototype_gpt_cert = 'prototype_gpt_cert',
    cto_cert = 'cto_cert',
    founders_cert = 'founders_cert',
    executive_cert = 'executive_cert',
    cmo_cert = 'cmo_cert',
    ai_for_technical_leaders_cert = 'ai_for_technical_leaders_cert',
}

export enum ProgramFamily {
    master_of_business_administration = 'master_of_business_administration',
    master_of_science_in_business_analytics = 'master_of_science_in_business_analytics',
    master_of_science_in_software_engineering = 'master_of_science_in_software_engineering',
    valar_leadership_accelerator = 'valar_leadership_accelerator',
    career_network_only = 'career_network_only',
    the_business_certificate = 'the_business_certificate',
    jordanian_math = 'jordanian_math',
    exec_ed_certificate = 'exec_ed_certificate',
}

export type ProgramFamilyConfig = {
    programFamily: ProgramFamily;
    applicationFormConfigs: FormConfig[];
    openCoursesSidebarBoxContentKey?: string;
    applicationReceivedModalContinueCoursesKey?: string;
    programBoxNotAppliedMobileHtmlKey?: string;
};

// Whenever supportsNetworkAccess is true, networkProgramTitle should be set
export type WithNetworkAccess = {
    supportsNetworkAccess: true;
    networkProgramTitle: string;
};
// Whenever supportsNetworkAccess is false, networkProgramTitle should be left null
export type WithoutNetworkAccess = {
    supportsNetworkAccess: false;
    networkProgramTitle: null;
};

export type ProgramTypeConfigProgramField = Nullable<ProgramField>;

// Some programs, such as our degree programs, require a user to manually apply to the program in order to receive a ProgramApplication.
// The primary reason this flag was added was to help delineate between programs that support the notion of having a ProgramApplication (`supportsProgramApplications`)
// as part of the admissions process, and programs that require a user to manually apply to the program in order to receive a ProgramApplication.
// IE: ExecEd programs have `supportsProgramApplications = true` and `supportsManuallyApplyingToProgram = false` because they can receive a ProgramApplication
// without manually applying via our application form.
// A program that requires manual application must define an applicationFormConfigs.
export type WithManualApplication = {
    supportsManuallyApplyingToProgram: true;
    applicationFormConfigs: FormConfig[];
};

export type WithoutManualApplication = {
    supportsManuallyApplyingToProgram: false;
};

export type ProgramTypeConfig = {
    key: ProgramType;
    programField: ProgramTypeConfigProgramField;
    programFamily?: ProgramFamily;
    isMBA: boolean;
    isEMBA: boolean;
    isMSBA: boolean;
    isMSSE: boolean;
    isExecEd: boolean;
    isDegreeProgram: boolean;
    fullTitle: string;
    institutionID: BrandOrInstitutionId;
    label: string;
    learnMoreUrlBrandConfigProperty?: keyof BrandConfig;
    marketingPageUrl?: string;
    openCoursesSidebarBoxContentKey?: string;
    applicationReceivedModalContinueCoursesKey?: string;
    shortProgramTitle: string;
    supportsSchedule: boolean;
    supportsCourseSummary: boolean;
    branding: Brand;
    requiresTILADisclosure: boolean;
    supportsBotPage: boolean;
    completionDocumentType: CompletionDocumentType;
    requiresProctoring: boolean;
    showApplicationScholarshipEligibilityNotice: boolean;
    supportsBuyNowPayLater: boolean;
    supportsTranscriptDownload: boolean;
    supportsStudentDashboardConfig: boolean;
    supportsEnrollmentVerificationLetter: boolean;
    programPreferenceStateKeys: ProgramPreferencesStateKey[];

    // We have several programs, such as VLA and MiyaMiya, that have a different admissions process than the rest of our programs.
    // The process for these programs involves granting a ProgramInclusion directly to the user without requiring pre-requisite ProgramApplication
    // and AdmissionOffer records. For these programs, and others like them, we set `supportsProgramApplications` to false. The value for this
    // property should match the corresponding `supports_program_applications` value in `program_type_config.rb`.
    supportsProgramApplications: boolean;

    // NOTE: By default this is the same as shortProgramTitle, but some programs need "Certificate" or "Program"
    // added to the end when they are displayed alone (i.e. as a title/header, rather than in a sentence). In those cases,
    // we override standaloneShortProgramTitle.
    standaloneShortProgramTitle: string;
    transcriptProgramTitle?: string | null;
    standardMonthlyPayment?: number;
    applicationProgramTypeHeader: string | null;
    welcomePackageConfiguration: WelcomePackageConfiguration | null;
    libraryDatabases: ('statista' | 'one_business' | 'ibisworld')[];
} & (WithoutNetworkAccess | WithNetworkAccess) &
    (WithoutManualApplication | WithManualApplication);

export type WelcomePackageStudent = {
    name: string;
    locationString: string;
    jobTitle: string;
    company: string;
    university: string;
    degree: string;
    avatarUrl: string;
};

export type WelcomePackageConfiguration = {
    badgeSrc: BrandConfig['programWelcomePackageBadge'];
    headlineKey: string;
    videoSrc: string | null;
    students: WelcomePackageStudent[];
};
