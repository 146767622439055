window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.playlists = window.locales.am.playlists || {};window.locales.am.playlists.playlist_library = window.locales.am.playlists.playlist_library || {};window.locales.am.playlists.playlist_library = {...window.locales.am.playlists.playlist_library, ...{
    "curated_playlists": "የተመረጡ የአጫዋች ዝርዝሮች",           
    "my_playlists": "የእኔ የአጫዋች ዝርዝሮች",           
    "title": "የአጫዋች ዝርዝሮች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.playlists = window.locales.ar.playlists || {};window.locales.ar.playlists.playlist_library = window.locales.ar.playlists.playlist_library || {};window.locales.ar.playlists.playlist_library = {...window.locales.ar.playlists.playlist_library, ...{
    "curated_playlists": "قوائم التشغيل",           
    "my_playlists": "قوائم التشغيل الخاصة بي",           
    "title": "قوائم التشغيل"           
}
};window.locales.en = window.locales.en || {};window.locales.en.playlists = window.locales.en.playlists || {};window.locales.en.playlists.playlist_library = window.locales.en.playlists.playlist_library || {};window.locales.en.playlists.playlist_library = {...window.locales.en.playlists.playlist_library, ...{
    "curated_playlists": "CURATED PLAYLISTS",
    "my_playlists": "MY PLAYLISTS",
    "title": "PLAYLISTS"
}
};window.locales.es = window.locales.es || {};window.locales.es.playlists = window.locales.es.playlists || {};window.locales.es.playlists.playlist_library = window.locales.es.playlists.playlist_library || {};window.locales.es.playlists.playlist_library = {...window.locales.es.playlists.playlist_library, ...{
    "title": "PLAYLISTS",           
    "curated_playlists": "PLAYLISTS SELECCIONADAS",           
    "my_playlists": "MIS PLAYLISTS"           
}
};window.locales.it = window.locales.it || {};window.locales.it.playlists = window.locales.it.playlists || {};window.locales.it.playlists.playlist_library = window.locales.it.playlists.playlist_library || {};window.locales.it.playlists.playlist_library = {...window.locales.it.playlists.playlist_library, ...{
    "curated_playlists": "PLAYLIST SCELTE",           
    "my_playlists": "LE MIE PLAYLIST",           
    "title": "PLAYLIST"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.playlists = window.locales.zh.playlists || {};window.locales.zh.playlists.playlist_library = window.locales.zh.playlists.playlist_library || {};window.locales.zh.playlists.playlist_library = {...window.locales.zh.playlists.playlist_library, ...{
    "curated_playlists": "已创建任务列表",           
    "my_playlists": "我的任务列表",           
    "title": "任务列表"           
}
};