/* eslint-disable react/jsx-wrap-multilines */
import clsx from 'clsx';
import { ExpandableCardList, ExpandableCardListContext } from 'ExpandableContainer';
import { type ChallengesComponent, type ChallengeComponent, type Stream } from 'Lessons';
import { isEqual, startCase } from 'lodash/fp';
import { memo, useContext, type FC } from 'react';
import { ExamChallengeBody } from './ExamChallengeBody';
import { type ExamChallengeEntry } from './PlaylistExamEvaluation.types';
import { IgnorableWarningIcon } from './IgnorableWarningIcon';

const classes = {
    header: {
        container: clsx(['flex', 'w-full']),
        warningIcon: {
            container: clsx(['flex', 'shrink-0', 'grow-0', 'basis-5', 'justify-center', 'self-start']),
            icon: clsx(['-ms-3', 'mt-2', 'text-lg']),
        },
        challenge: {
            container: clsx(['mx-2', 'flex', 'grow', 'flex-col', 'justify-start']),
            challengeNumber: {
                container: clsx(['mb-1']),
                number: clsx(['me-2', 'text-md', 'font-bold']),
            },
            prompt: {
                container: clsx(['pe-3', 'leading-[1.1rem]']),
                label: (open: boolean) => clsx(!open && 'line-clamp-1 overflow-ellipsis'),
                promptType: 'font-semibold',
            },
            challengeType: clsx(['text-xs', 'text-gray-600']),
        },
    },
    card: clsx(['py-1', 'px-2', 'mb-4', 'bg-gray-50']),
    cardButton: clsx(['items-start', 'mt-3']),
};

type ChallengeHeaderProps = {
    challenge: ChallengeComponent;
    challengesComponent: ChallengesComponent;
    challengeIndex: number;
    examChallengeEntry: ExamChallengeEntry;
    isSoleChallengeOnFrame: boolean;
};

function ChallengeHeader({
    challengesComponent,
    challengeIndex,
    challenge,
    examChallengeEntry,
    isSoleChallengeOnFrame,
}: ChallengeHeaderProps) {
    const { open } = useContext(ExpandableCardListContext);
    const prompt = challengesComponent.getPrompt(challenge);
    const promptType =
        prompt.image && challengesComponent.hasBlanks
            ? 'Image Blank'
            : prompt.image
            ? 'Image'
            : challengesComponent.hasBlanks
            ? 'Blank'
            : prompt.text
            ? 'Prompt'
            : null;

    const promptLabel = prompt.text || null;

    // Show a warning if the challenge is unfair or if it has no instructional frames
    const showWarning = examChallengeEntry.fair === false || examChallengeEntry.instructionalFrameIds.length === 0;

    return (
        <div className={classes.header.container}>
            <div className={classes.header.warningIcon.container}>
                {showWarning && (
                    <IgnorableWarningIcon
                        ignored={examChallengeEntry.ignored}
                        className={classes.header.warningIcon.icon}
                    />
                )}
            </div>
            <div className={classes.header.challenge.container}>
                <div className={classes.header.challenge.challengeNumber.container}>
                    <strong className={classes.header.challenge.challengeNumber.number}>
                        Challenge {isSoleChallengeOnFrame ? '' : challengeIndex + 1}
                    </strong>
                    <span className={classes.header.challenge.challengeType}>
                        {startCase(challenge.editor_template)}
                    </span>
                </div>
                {promptType && (
                    <div className={classes.header.challenge.prompt.container} data-testid="prompt">
                        <span className={classes.header.challenge.prompt.label(open)}>
                            <strong className={classes.header.challenge.prompt.promptType}>
                                {promptType}
                                {!!promptLabel && ': '}
                            </strong>
                            {promptLabel}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

type Props = {
    challengeIndex: number;
    examChallengeEntry: ExamChallengeEntry;
    streams: Stream<true>[];
    isSoleChallengeOnFrame: boolean;
    challenge: ChallengeHeaderProps['challenge'];
    challengesComponent: ChallengeHeaderProps['challengesComponent'];
};

export const ExamChallengeCard: FC<Props> = memo(
    ({ challenge, challengesComponent, challengeIndex, examChallengeEntry, streams, isSoleChallengeOnFrame }) => (
        <ExpandableCardList
            header={
                <ChallengeHeader
                    isSoleChallengeOnFrame={isSoleChallengeOnFrame}
                    challengeIndex={challengeIndex}
                    challenge={challenge}
                    challengesComponent={challengesComponent}
                    examChallengeEntry={examChallengeEntry}
                />
            }
            initiallyExpanded={isSoleChallengeOnFrame}
            body={<ExamChallengeBody examChallengeEntry={examChallengeEntry} streams={streams} />}
            cardClassName={classes.card}
            buttonClassName={classes.cardButton}
        />
    ),
    isEqual,
);
